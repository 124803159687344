.station-list {
    position: relative;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 100px;

    &-item {
        font-size: 0.9rem;
        background-color: #ffffff;
        border-color: #d8d8d8;
        border-radius: 15px;
        box-shadow:
            0px 1px 2px 0px rgba(0, 0, 0, 0.06),
            0px 1px 3px 0px rgba(0, 0, 0, 0.1);

        &.selected,
        &.desktop:hover {
            cursor: pointer;
            background-color: #cfe1fc;
            box-shadow:
                0px 2px 4px -1px rgba(0, 0, 0, 0.06),
                0px 4px 6px -1px rgba(0, 0, 0, 0.1);
        }

        .click-hint {
            position: relative;
            font-size: 0.5rem;
            margin: 5px 10px -10px 0;
            display: block;
            text-align: end;
        }
    }
}
