.app {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;

  .left-sidebar {
    position: relative;
    min-width: 360px;
    max-width: 600px;
    width: 40%;
    background-color: #f3f6f8;
  }

  .main-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.btn {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  flex-wrap: nowrap;

  svg {
    font-size: 1rem;
  }
}

.modal.mobile {
  > .modal-dialog {
    align-items: flex-end;
    display: flex;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
  }
}
