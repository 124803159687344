.station-details {
    position: relative;
    border-radius: 5px;
    padding: 15px;

    .title {
        margin: 0px !important;

        .name {
            font-size: 1rem;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 0px;
        }

        .address {
            font-size: 0.8rem;
        }

        .distance {
            font-size: 0.8rem;

            &.is-close-enough {
                color: green;
            }
        }

        p {
            margin-bottom: 5px;
        }
    }

    .separator {
        border-style: solid;
        border-width: 1px;
        margin: 5px;
        border-color: #ededed;
    }

    .last-update {
        font-size: 0.8rem;
        font-style: italic;
        margin-bottom: 0px;
    }

    .oil {
        display: flex;
        gap: 10px;
        margin-bottom: 5px;

        .icon {
            border: 1px gray solid;
        }
    }

    .oils {
        margin-top: 10px;
    }

    .action-buttons {
        margin-top: 10px;
        .btn {
            width: 100%;
        }
    }

    .share-station {
        position: absolute;
        right: 10px;
    }

    .share-btn {
        border-radius: 18px;
        padding: 0px;
        width: 35px;
        height: 35px;
        border-width: 0px;
    }
}
