.map {
    height: 100vh;

    .mobile-map-btn {
        position: absolute;
        border: 0px;
        right: 15px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: flex-end;

        &.top-right {
            top: 15px;
        }

        &.bottom-left {
            bottom: 20px;
        }

        button {
            border-width: 0px;
            border-radius: 25px;
            height: 50px;

            > svg {
                font-size: 30px;
            }
        }
    }

    .my-location-btn {
        background: white;
        color: #0d6efd;
        width: 50px;

        &.unfollow {
            color: gray;
        }

        .tilted-line {
          position: absolute;
          width: 80%;
          height: 2px;
          background-color: black;
          transform: rotate(-45deg);
          top: 77%;
          left: 21%;
          transform-origin: left center;
          background-color: gray;
        }
    }
}
