.stations-filtering {
    background-color: white;
    border-radius: 5px;

    .btn {
        width: 100%;
    }

    .radius {
        .slider {
            display: flex;

            span {
                width: 30%;

                &.max {
                    text-align: end;
                }
            }
        }
    }
}
