.station-selected-modal {
    .modal-content {
        .modal-header {
            border: 0;
            padding-bottom: 0px;
        }

        .modal-body {
            padding: 0px;
        }
    }
}
