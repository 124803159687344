.fuel-form-input {
    .input-group-text {
        justify-content: center;

        &.fuel-name {
            width: 55%;
        }

        &.change-price-btn {
            &.increment {
                background-color: #d6f4d2;
                &:active {
                    background-color: #c1ebc2;
                }
            }

            &.decrement {
                background-color: #ffdbdb;
                &:active {
                    background-color: #fcbbbb;
                }
            }
        }
    }
}

.prices-form {
    display: flex;
    flex-direction: column;
}
